import * as React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import i18n from "../components/i18n";
import { Trans, withTranslation, useTranslation } from "react-i18next";
import Link from "../components/link";
import Layout from "../components/layout";
import NavigationHelper from "../helpers/navigation";
import Seo from "../components/seo";

/** Images import */
import img_slider1 from "../images/a1_cloud_migration_services_testimonials_header_bg.webp";
import img_video_upfactor from "../images/upfactor_a1_cloud_testimonial_preview.webp";
import img_video_javelo from "../images/javelo_a1_cloud_testimonial_preview.webp";
import img_tpop_arrow_icon from "../images/tpop-arrow-icon.png";
import img_photo_man from "../images/thumb_video_upfactor.webp";
import img_thumb_javelo from "../images/thumb_video_javelo.webp";

// markup
const TestimonialsPage = (props) => {
  const [activeLanguage, setActiveLanguage] = useState();
  useEffect(() => {
    setActiveLanguage(i18n.language);
  }, [setActiveLanguage]);

  const { t } = useTranslation(["main", "forms"]);
  const LocalizedLink = withTranslation("main")(Link);

  // Navigation info
  const pageInfo = {
    key: "testimonials",
    lang: i18n.language,
  };

  return (
    <Layout location={props.location} pageInfo={pageInfo}>
      <Helmet
        bodyAttributes={{
          class: "testi-monial-main-page",
        }}
      >
        <title>{t("main:testimonials_main_content_title")}</title>
      </Helmet>

      <Seo
        description={t("main:testimonials_meta_seo-description_txt")}
        title={t("main:testimonials_meta_seo-title_txt")}
        keywords={[
          t("main:testimonials_meta_seo-keyword1_txt"),
          t("main:testimonials_meta_seo-keyword2_txt"),
          t("main:testimonials_meta_seo-keyword3_txt"),
          t("main:testimonials_meta_seo-keyword4_txt"),
          t("main:testimonials_meta_seo-keyword5_txt"),
          t("main:testimonials_meta_seo-keyword6_txt"),
          t("main:testimonials_meta_seo-keyword7_txt"),
        ]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_logo.png`}
      />

      <div className="main-content">
        {/* breadcumbs */}
        <div className="site-breadcumbs">
          <div className="container">
            <small>
              <a
                href={NavigationHelper.useGeneratePageURL(
                  activeLanguage,
                  "home"
                )}
                title={t("main:global_header_home_menu-item-seo-title_txt")}
              >
                A1:
              </a>{" "}
              // {t("main:testimonials_header_breadcrumb_title")}
            </small>
          </div>
        </div>
        {/* end breadcumbs */}

        <div
          className="vos_testi service_header"
          style={{ backgroundImage: `url(${img_slider1})` }}
        >
          <div className="container">
            <div className="title_block">
              <h1 className="nos-service">
                {t("main:testimonials_header_hero_title")}
              </h1>
            </div>
            <div className="row">
              <div className="col-md-5"></div>
              <div className="col-md-7">
                <div className="block_text">
                  <h2 className="h2_text ipade_up">
                    <span>
                      <Trans i18nKey="main:testimonials_header_tagline_html">
                        <strong>Vos précieux retours</strong>
                        <br />
                        <strong>nous font progresser</strong>
                      </Trans>
                    </span>
                  </h2>
                  <h2 class="h2_text ipade_down">
                    <span>
                      <Trans i18nKey="main:testimonials_header_tagline_html">
                        <strong>Vos précieux retours</strong>
                        <br />
                        <strong>nous font progresser</strong>
                      </Trans>
                    </span>
                  </h2>
                  <div className="p_text ipade_up">
                    <Trans i18nKey="main:testimonials_header_block_html">
                      <p>
                        Nous les avons aidés à réussir dans le Cloud et ils
                        partagent leurs expériences.
                      </p>
                      <p>
                        Les témoignages de ces entreprises vous aideront à
                        cerner comment vous pourrez mieux innover grâce à
                        l'accompagnement d'
                        <strong style={{ fontStyle: "italic" }}>A</strong>1
                        Cloud.
                      </p>
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* testi-monial-page-section */}
        <div className="testimonial_sec vostemo_sec">
          <div className="container">
            <div className="home-testimonial-slider ">
              <div className="slider-block">
                <div className="img-box desktop_view">
                  <LocalizedLink
                    link={NavigationHelper.useGeneratePageURL(
                      activeLanguage,
                      "client_testimonial",
                      "upfactor"
                    )}
                    title={t("main:single-testimonial_see-video-seo-link_txt", {
                      clientname: "UpFactor",
                    })}
                  >
                    <div className="res_img">
                      <img
                        src={img_video_upfactor}
                        alt={t("main:testimonials_main_video-image-alt_txt", {
                          clientname: "UpFactor",
                        })}
                      />
                      <div className="play-icon">
                        <i className="fa fa-play" aria-hidden="true" />
                      </div>
                    </div>
                  </LocalizedLink>
                </div>
                <div className="text-block">
                  <div className="text-block-img">
                    <span className="quot_img">
                      <img src={img_tpop_arrow_icon} />
                    </span>
                    <div className="user_img">
                      <img
                        className="thumbnail_img"
                        src={img_photo_man}
                        alt={t("main:global_person_photo-alt_txt", {
                          name: "Didier Mignery",
                        })}
                      />
                    </div>
                  </div>
                  <div className="text-block-right-text">
                    <h2>
                      <q>{t("main:testimonials_main_upfactor_title")}</q>
                    </h2>
                    <div className="img-box mobile_view">
                      <LocalizedLink
                        link={NavigationHelper.useGeneratePageURL(
                          activeLanguage,
                          "client_testimonial",
                          "upfactor"
                        )}
                        title={t(
                          "main:single-testimonial_see-video-seo-link_txt",
                          { clientname: "UpFactor" }
                        )}
                      >
                        <div className="res_img">
                          <img
                            src={img_video_upfactor}
                            alt={t(
                              "main:testimonials_main_video-image-alt_txt",
                              { clientname: "UpFactor" }
                            )}
                          />
                          <div className="play-icon">
                            <i className="fa fa-play" aria-hidden="true" />
                          </div>
                        </div>
                      </LocalizedLink>
                    </div>
                    <h5>
                      {t(
                        "main:single-testimonial_main_upfactor-interviewee-name_txt"
                      )}
                    </h5>
                    <span>
                      {t(
                        "main:single-testimonial_main_upfactor-interviewee-position_txt"
                      )}
                    </span>
                    <p>{t("main:testimonials_main_upfactor_txt")}</p>
                    <LocalizedLink
                      link={NavigationHelper.useGeneratePageURL(
                        activeLanguage,
                        "client_testimonial",
                        "upfactor"
                      )}
                      title={t(
                        "main:single-testimonial_see-video-seo-link_txt",
                        { clientname: "UpFactor" }
                      )}
                    >
                      {t("main:testimonials_main_see-testimonial_link")}
                    </LocalizedLink>
                  </div>
                </div>
              </div>
              <div className="slider-block">
                <div
                  className="text-block"
                  style={{ paddingLeft: 0, paddingRight: 50 }}
                >
                  <div className="text-block-img">
                    <span className="quot_img">
                      <img src={img_tpop_arrow_icon} />
                    </span>
                    <div className="user_img">
                      <img
                        className="thumbnail_img"
                        src={img_thumb_javelo}
                        alt={t("main:global_person_photo-alt_txt", {
                          name: "Bakari Sumaila",
                        })}
                      />
                    </div>
                  </div>
                  <div className="text-block-right-text">
                    <h2>
                      <q>{t("main:testimonials_main_javelo_title")}</q>
                    </h2>
                    <div className="img-box mobile_view">
                      <LocalizedLink
                        link={NavigationHelper.useGeneratePageURL(
                          activeLanguage,
                          "client_testimonial",
                          "javelo"
                        )}
                        title={t(
                          "main:single-testimonial_see-video-seo-link_txt",
                          { clientname: "Javelo" }
                        )}
                      >
                        <div className="res_img">
                          {" "}
                          <img
                            src={img_video_javelo}
                            alt={t(
                              "main:testimonials_main_video-image-alt_txt",
                              { clientname: "Javelo" }
                            )}
                          />
                          <div className="play-icon">
                            <i className="fa fa-play" aria-hidden="true" />
                          </div>
                        </div>
                      </LocalizedLink>
                    </div>
                    <h5>
                      {t(
                        "main:single-testimonial_main_javelo-interviewee-name_txt"
                      )}
                    </h5>
                    <span>
                      {t(
                        "main:single-testimonial_main_javelo-interviewee-position_txt"
                      )}
                    </span>
                    <p>{t("main:testimonials_main_javelo_txt")}</p>
                    <LocalizedLink
                      link={NavigationHelper.useGeneratePageURL(
                        activeLanguage,
                        "client_testimonial",
                        "javelo"
                      )}
                      title={t(
                        "main:single-testimonial_see-video-seo-link_txt",
                        { clientname: "Javelo" }
                      )}
                    >
                      {t("main:testimonials_main_see-testimonial_link")}
                    </LocalizedLink>
                  </div>
                </div>
                <div className="img-box desktop_view">
                  <LocalizedLink
                    link={NavigationHelper.useGeneratePageURL(
                      activeLanguage,
                      "client_testimonial",
                      "javelo"
                    )}
                    title={t("main:single-testimonial_see-video-seo-link_txt", {
                      clientname: "Javelo",
                    })}
                  >
                    <div className="res_img">
                      {" "}
                      <img
                        src={img_video_javelo}
                        alt={t("main:testimonials_main_video-image-alt_txt", {
                          clientname: "Javelo",
                        })}
                      />
                      <div className="play-icon">
                        <i className="fa fa-play" aria-hidden="true" />
                      </div>
                    </div>
                  </LocalizedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* main content end */}
    </Layout>
  );
};

export default TestimonialsPage;
